<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <a v-bind="attrs" style="color: white;" v-on="on"> (clique aqui para ver detalhes)</a>
    </template>

    <v-card style="overflow-x:hidden">
      <v-card-title>
        Detalhes do erro
      </v-card-title>

      <v-card-text>
        <h3>Linha {{ error.line }}</h3>
        <span>{{ error.details }}</span>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <mf-action-buttons
          :primary-button="{
            text: 'Cancelar',
            action: () => (dialog = false),
            isVisible: true
          }"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    error: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    dialog: false
  })
}
</script>
